import PropTypes from 'prop-types';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripLinesVertical,
} from '@fortawesome/free-solid-svg-icons';

import TextModule from './text-module';
import HeroModule from './hero-module';
import ImageModule from './image-module';
import YoutubeModule from './youtube-module';
import CardModule from './card-module';
import SmallCardModule from './small-card-module';
import CtaModule from './cta-module';
import ContactFormModule from './contact-form-module';
import ThreeStepButtonModule from './three-step-button-module';
import BoxModule from './box-module';
import ButtonCardModule from './button-card-module';


const components = {
  text_module: [TextModule, 'Text'],
  hero_module: [HeroModule, 'Hero banner'],
  image_module: [ImageModule, 'Image'],
  youtube_module: [YoutubeModule, 'YouTube video'],
  card_module: [CardModule, 'Card'],
  small_card_module: [SmallCardModule, 'Card (small)'],
  cta_module: [CtaModule, "Call to action button"],
  contact_form_module: [ContactFormModule, 'Contact form'],
};


export default function ContentModule({ dragHandleProps, token, logout, data, onFieldChange, removeColumn, websiteReference }) {
  const component = data.partial || 'select';
  const Module = components[component] ? components[component][0] : null;
  const moduleName = components[component] ? components[component][1] : 'Select module';

  // Modules exclusive to GCRP
  if(websiteReference === 'gcrp') {
    components['three_step_button_module'] = [ThreeStepButtonModule, 'Three step button'];
    components['box_module'] = [BoxModule, 'Box'];
    components['button_card_module'] = [ButtonCardModule, 'Button card'];
  }

  const options = [];
  Object.keys(components).forEach(key => {
    options.push({ value: key, label: components[key][1] });
  });

  const onDelete = () => {
    if(component === 'select' || window.confirm(`Delete column? This cannot be undone!`)) {
      removeColumn(data.id);
    }
  };

  return (
    <>
      <div className="card">
      <header className="card-header drag-handle" {...dragHandleProps}>
          <p className="card-header-title">{component !== 'select' && !Module ? component : moduleName}</p>
          <span className="card-header-icon">
            <span className="icon">
              <FontAwesomeIcon icon={faGripLinesVertical} />
            </span>
          </span>
        </header>

        <div className={component !== 'text_module' ? 'card-content' : ''}>
          {component === 'select' && (
            <Select menuPortalTarget={document.body}
                    options={options}
                    onChange={v => onFieldChange(data.id, 'partial', v.value)} />
          )}
          {component !== 'select' && Module && (
            <Module token={token}
                    logout={logout}
                    data={data}
                    onFieldChange={onFieldChange}
                    websiteReference={websiteReference} />
          )}
          {component !== 'select' && !Module && (
            <p>Content not managed by the CMS</p>
          )}
        </div>
        <footer className="card-footer">
          <button onClick={onDelete} className="button is-ghost card-footer-item">Delete</button>
        </footer>
      </div>
    </>
  );
}


ContentModule.propTypes = {
  dragHandleProps: PropTypes.any.isRequired,
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  removeColumn: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
