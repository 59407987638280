import PropTypes from 'prop-types';

import MarkdownEditor from '../page-editor/markdown-editor';


export default function GlobalContentEditor({ data, setData, websiteReference }) {
  return (
    <>
      <hr />

      <div className="field">
        <label className="label" htmlFor="title">Website title</label>
        <div className="control">
          <input id="title"
                 className="input"
                 type="text"
                 placeholder="Acme Corporation"
                 value={data.title || ''}
                 onChange={e => setData({...data, title: e.target.value})}
          />
        </div>
        <p className="help">Website title added to the end of page titles, for example: <strong>Lorem ipsum dolor sit amet | {data.title || 'Acme Corporation'}</strong></p>
      </div>

      {/* No telephone fields on GCRP */}
      {websiteReference !== 'gcrp' && (
        <>
          <div className="field">
            <label className="label" htmlFor="telephone">Telephone number</label>
            <div className="control">
              <input id="telephone"
                     className="input"
                     type="tel"
                     placeholder="01234 567890"
                     value={data.telephone || ''}
                     onChange={e => setData({...data, telephone: e.target.value})}
              />
            </div>
            <p className="help">Telephone number displayed to users</p>
          </div>

          <div className="field">
            <label className="label" htmlFor="telephone_international">Telephone with country code</label>
            <div className="control">
              <input id="telephone_international"
                     className="input"
                     type="tel"
                     pattern="[+]{1}[0-9]{11,14}"
                     placeholder="+441234567890"
                     value={data.telephone_international || ''}
                     onChange={e => setData({...data, telephone_international: e.target.value})}
              />
            </div>
            <p className="help">Full telephone number including the country code prefix</p>
          </div>
        </>
      )}

      {/* GCRP map page notification box */}
      {websiteReference === 'gcrp' && (
        <div className="field">
          <label className="label">Station page notification box</label>
          <div className="control">
            {/* Wait until the API request is finished before rendeing MarkdownEditor */}
            {typeof data['station-page-notification'] !== 'undefined'  && (
              <MarkdownEditor content={data['station-page-notification'] || ''} setContent={c => setData({...data, 'station-page-notification': c})} />
            )}
          </div>
        </div>
      )}

    </>
  );
}


GlobalContentEditor.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
