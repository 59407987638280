import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
} from '@fortawesome/free-solid-svg-icons';


export default function PublishButton({ deploy, deploying, unpublished, saved }) {
  const onPublish = (event) => {
    event.preventDefault();
    if(event.target.disabled) return false;
    deploy();
  }

  let statusText;
  if(deploying === 'done') {
    statusText = <span className="tag">All changes published</span>
  }
  if(unpublished) {
    statusText = <span className="tag is-warning">Unpublished changes</span>
  }
  if(deploying === 'deploying') {
    statusText = <span className="tag">Publishing content... (you can leave this page)</span>
  }
  if(deploying === 'error') {
    statusText = <span className="tag is-danger">Error publishing changes, please contact support</span>
  }

  return (
    <>
      <div className="level-item">
        <button className={`button is-primary is-medium ${deploying === 'deploying' && 'is-loading'}`}
                onClick={onPublish}
                disabled={!unpublished || !saved} >
          <span className="icon">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <span>Publish</span>
        </button>
      </div>
      <div className="level-item">
        {statusText}
      </div>
  </>
  );
}


PublishButton.propTypes = {
  deploy: PropTypes.func.isRequired,
  deploying: PropTypes.string.isRequired,
  unpublished: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};
