import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWindowRestore,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';

import api from '../api';


export default function WebsiteList({ token, logout }) {
  const [websites, setWebsites] = useState([]);

  useEffect(() => {
    api('/websites', token, logout)
      .then(response => {
        let websites = Object.values(response);
        websites = websites.sort((a, b) => a.reference.localeCompare(b.reference));
        setWebsites(websites);
      });
  }, [token, logout]);

  return(
    <div className="container is-max-desktop">
      <h2 className="title is-2 mb-6">
        <span className="icon is-small is-right has-text-primary">
          <FontAwesomeIcon icon={faWindowRestore} />
        </span>
        Your websites
      </h2>
      <div className="box">
        <ul className="page-site-list">
          <>
            {websites.map(website => (
              <li key={website.reference}>
                <span className="icon is-small is-right mr-3 has-text-primary">
                  <FontAwesomeIcon icon={faWindowMaximize} />
                </span>
                <Link to={`/websites/${website.reference}`}>{website.reference}</Link>
              </li>
            ))}
          </>
        </ul>
      </div>
    </div>
  );
}


WebsiteList.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};
