import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { BASE_URL } from '../api';


async function requestPasswordReset(email) {
  return fetch(`${BASE_URL}/reset-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({email: email}),
  });
}


export default function PasswordReset() {
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(false);

    requestPasswordReset(email)
      .then(response => {
        if(response.ok) {
          setSuccess(true);
        } else {
          setError(true);
        }
      })
      .then(response => setLoading(false));
  }

  let statusText;
  if(error) {
    statusText = <span className="tag is-danger">An unknown error occurred, please contact suppport</span>
  } else if(success) {
    statusText = <span className="tag is-success">Password reset requested, please check your inbox</span>
  }

  return(
    <div className="columns">
      <div className="column">
        <div className="box form-box animate pop delay-1">
          <h1 className="title mb-5">Reset your password</h1>

          <p>Enter your email address and we'll send you a link to change your password.</p>

          <form onSubmit={handleSubmit} className="pt-4">
            <div className="field">
              <p className="control has-icons-left">
                <input className="input"
                       type="email"
                       autoComplete="username"
                       id="email"
                       name="email"
                       placeholder="Email"
                       required
                       onChange={e => {
                         setEmail(e.target.value);
                         setSuccess(false);
                         setError(false);
                       }} />
                <span className="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </p>
            </div>
            <div className="field mt-5">
              <p className="control">
                <button className={`button is-primary ${loading && 'is-loading'}`}
                        disabled={success}
                        type="submit" >
                  Reset password
                </button>
              </p>
            </div>
            {statusText}
          </form>
        </div>       
      </div>
    </div>
  );
}
