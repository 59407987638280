import ImageUpload from '../page-editor/image-upload';
import MarkdownEditor from '../page-editor/markdown-editor';


export default function CardModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label">Image</label>
        <ImageUpload token={token}
                     logout={logout}
                     srcField="image_src"
                     data={data}
                     onFieldChange={onFieldChange}
                     websiteReference={websiteReference} />
      </div>

      <div className="field">
        <label className="label" htmlFor={'image_alt' + id}>Image alt text</label>
        <div className="control">
          <input id={'image_alt' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.image_alt}
                 onChange={e => {onFieldChange(data.id, 'image_alt', e.target.value)}}
          />
          <p className="help">Text that clearly and and concisely describes the image's content</p>
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'image_title' + id}>Image title</label>
        <div className="control">
          <input id={'image_title' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.image_title}
                 onChange={e => {onFieldChange(data.id, 'image_title', e.target.value)}}
          />
          <p className="help">Text that appears as a tooltip when hovering over the image</p>
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'title' + id}>Title</label>
        <div className="control">
          <input id={'title' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.title}
                 onChange={e => {onFieldChange(data.id, 'title', e.target.value)}} />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'subtitle' + id}>Subtitle</label>
        <div className="control">
          <input id={'subtitle' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.subtitle}
                 onChange={e => {onFieldChange(data.id, 'subtitle', e.target.value)}}
          />
        </div>
      </div>

      <div className="field">
        <label className="label">Content</label>
        <MarkdownEditor content={data.content} setContent={c => onFieldChange(data.id, 'content', c)} />
      </div>
    </>
  );
}
