import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

import api, { BASE_URL } from '../api';


async function changePassword(token, password) {
  return fetch(`${BASE_URL}/change-password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({token: token, password: password}),
  });
}


export default function PasswordChange({ resetToken, logout }) {
  const [tokenValid, setTokenValid] = useState(true);
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // Check the validity of the reset token
  useEffect(() => {
    api(`/change-password?token=${resetToken}`, '', () => setTokenValid(false))
      .then(response => setTokenValid(response.ok));
  }, [resetToken]);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false);
    setError(false);

    changePassword(resetToken, password)
      .then(async response => {
        if(response.ok) {
          logout();
          setSuccess(true);
        } else {
          let json;
          try {
            json = await response.json();
          } catch(e) {}
          setError(json ? json.message : 'An unknown error occurred, please contact suppport');
        }
      })
      .then(response => setLoading(false));
  }

  return(
    <div className="columns">
      <div className="column">
        
        <div className="box form-box animate pop delay-1">

          {success && (
            <article className="message is-success">
              <div className="message-header">
                <p>Success</p>
              </div>
              <div className="message-body">
                Password successfully changed, please <Link to="/">log in again</Link>.
              </div>
            </article>
          )}

          {!success && !tokenValid && (
            <article className="message is-danger">
              <div className="message-header">
                <p>Password reset link expired</p>
              </div>
              <div className="message-body">
                <p>Reset links are only valid for up to 30 minutes. Please click the button below to request a new password reset.</p>
                <Link to="/password/reset" className="button mt-4">Reset password</Link>
              </div>
            </article>
          )}

          {!success && tokenValid && (
            <>
          <h1 className="title mb-5">Change your password</h1>

          <p>Your password must be at least 8 characters long.</p>

          <form onSubmit={handleSubmit} className="pt-4">
            <div className="field">
              <p className="control has-icons-left">
                <input className="input"
                       type="password"
                       pattern=".{8,64}"
                       autoComplete="new-password"
                       id="new-password"
                       placeholder="New password"
                       required
                       onChange={e => {
                         setPassword(e.target.value);
                         setError('');
                       }} />
                <span className="icon is-small is-left has-text-primary">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </p>
            </div>
            <div className="field mt-5">
              <p className="control">
                <button className={`button is-primary ${loading && 'is-loading'}`}
                        type="submit" >
                  Change password
                </button>
              </p>
            </div>
            {error && (
              <span className="tag is-danger">{error}</span>
            )}
          </form>
            </>
          )}
        </div>       
      </div>
    </div>
  );
}


PasswordChange.propTypes = {
  resetToken: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
};
