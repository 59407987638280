import PropTypes from 'prop-types';


export default function SavedStatus({ saved }) {
  return (
    <>
      {saved && (
        <span className="tag">Changes saved</span>
      )}
      {!saved && (
        <span className="tag">Saving changes...</span>
      )}
    </>
  );
}


SavedStatus.propTypes = {
  saved: PropTypes.bool.isRequired,
};
