import { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEarthEurope,
} from '@fortawesome/free-solid-svg-icons';

import api                 from '../api';
import GlobalContentEditor from './global-content-editor';
import PublishButton       from '../publish-button/publish-button';
import SavedStatus         from '../saved-status/saved-status';


export default function GlobalContent({ token, logout, reference }) {
  const [data, setData] = useState({});
  const [unpublished, setUnpublished] = useState(false);
  const [deploying, setDeploying] = useState('');
  const [saved, setSaved] = useState(true);
  const dataEndpoint = `/websites/${reference}/data/global`;
  const metadataEndpoint = `/websites/${reference}/metadata/global`;
  const deployEndpoint = `/websites/${reference}/deploy`;
  const autosave = useRef(null);

  // Save the data and occasionally send it to the API
  const saveData = d => {
    setData(d);
    setSaved(false);

    if(autosave.current) clearTimeout(autosave.current);
    autosave.current = setTimeout(() => {
      autosave.current = null;
      api(`${dataEndpoint}?unpublished=1`, token, logout, {
        method: 'PUT',
        body: JSON.stringify(d),
      })
        .then(() => {
          setSaved(true);
          api(metadataEndpoint, token, logout)
            .then(response => setUnpublished(response.unpublished_changes));
        });
    }, 1000);
  };

  useEffect(() => {
    api(`${dataEndpoint}?unpublished=1`, token, logout)
      .then(response => {
        if(response.error) response = {};
        setData(response);
      });
  }, [token, logout, dataEndpoint]);

  // Deploy unpublished changes to the website
  const publishChanges = () => {
    setDeploying('deploying');
    api(dataEndpoint, token, logout, {
      method: 'PUT',
      body: JSON.stringify(data),
    })
      .then(() => {
        api(deployEndpoint, '', logout, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`
          },
        })
          .then(response => {
            setDeploying(response.status);
          });
      });
  };

  // Deploy status
  useEffect(() => {
    const getStatus = () => {
      api(deployEndpoint, token, logout)
        .then(response => setDeploying(response.status));
    };
    if(deploying && deploying !== 'deploying') return;
    const interval = setInterval(getStatus, 1000);
    return () => clearInterval(interval);
  }, [token, logout, deployEndpoint, deploying]);

  // Unpublished status
  useEffect(() => {
    api(metadataEndpoint, token, logout)
      .then(response => setUnpublished(response.unpublished_changes));
  }, [token, logout, metadataEndpoint, deploying]);

  return (
    <div className="container">
      <h2 className="title is-2 mb-6">
        <span className="icon is-small is-right has-text-primary">
          <FontAwesomeIcon icon={faEarthEurope} />
        </span>
        Global content
      </h2>

      <div>
        <nav className="level">
          <div className="level-left">
            <PublishButton deploy={publishChanges}
                           deploying={deploying}
                           unpublished={unpublished}
                           saved={saved} />
          </div>
          <div className="level-right">
            <div className="level-item">
              <SavedStatus saved={saved} />
            </div>
          </div>
        </nav>

        <GlobalContentEditor data={data} setData={saveData} websiteReference={reference} />

      </div>
    </div>
  );
}


GlobalContent.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  reference: PropTypes.string.isRequired,
};
