import PropTypes from 'prop-types';


export default function Switch({ value, setValue, id, label }) {
  return (
    <div className="field">
      <input id={id}
             type="checkbox"
             name="switchExample"
             className="switch is-rounded"
             checked={value}
             onChange={setValue}
      >
      </input>
      <label htmlFor={id}>{label}</label>
    </div>
  );
}


Switch.propTypes = {
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
