import footerImage from '../../images/footer-abstract.svg';
import logo        from '../../images/psand-logo.svg';


export default function Navbar({ logout, website }) {
  return (
    <div className="base">
      <div className="footer-abstract is-hidden-mobile">
        <img src={footerImage} alt=""></img>
      </div>
      <footer className="footer" >
        <div className="footer-info has-text-white is-size-6">
          <img className="my-4" src={logo} alt=""></img><br />
          <p><a className="has-text-white" href="https://www.psand.net/get-in-touch/">Get in touch</a></p>
          <p><a className="has-text-white" href="https://www.markdownguide.org/basic-syntax/">Learn Markdown</a></p>
        </div>
      </footer>
    </div>
  );
}
