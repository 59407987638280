import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import changing from '../../images/changing-pages.svg';


export default function Navbar({ token, logout, website, active }) {
  return (
    <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
      {token && (
        <button onClick={logout} className="button logout-button is-small is-secondary mt-1">Log out</button>
      )}
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <img src={changing} width="120" height="40" alt="" />
        </Link>

      </div>
      <div className="navbar-menu">
        <div className="navbar-start is-flex">
        {website && (
          <>
            <Link to={`/websites/${website}`} className="navbar-item website-title">
              <span>{website}</span>
            </Link>
            <Link to={`/websites/${website}/pages`} className={`navbar-item ${active === 'pages' && 'is-active'}`}>
              Pages
            </Link>
            <Link to={`/websites/${website}/global`} className={`navbar-item ${active === 'global' && 'is-active'}`}>
              Global content
            </Link>
            <Link to={`/websites/${website}/footer`} className={`navbar-item ${active === 'footer' && 'is-active'}`}>
              Footer
            </Link>
            {/* No navigation bar on GCRP or universal */}
            {website !== 'gcrp' && website !== 'universal' && (
            <Link to={`/websites/${website}/navigation`} className={`navbar-item ${active === 'navigation' && 'is-active'}`}>
              Navigation
            </Link>
            )}
          </>
        )}
        </div>
      </div>
    </nav>
  );
}


Navbar.propTypes = {
  token: PropTypes.string,
  logout: PropTypes.func.isRequired,
  website: PropTypes.string,
  active: PropTypes.string,
};
