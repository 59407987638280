import ImageUpload from '../page-editor/image-upload';
import Switch from '../switch/switch';


export default function ImageModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label">Image</label>
        <ImageUpload token={token}
                     logout={logout}
                     srcField="image_src"
                     data={data}
                     onFieldChange={onFieldChange}
                     websiteReference={websiteReference} />
      </div>

      {/* Background shadows are exclusive to ULS */}
      {websiteReference === 'universal' && (
        <div className="field">
          <Switch
            value={data.shadow}
            setValue={() => {
              data.shadow = !data.shadow;
              onFieldChange(data.id, 'shadow', data.shadow)
            }}
            id={'shadow$' + id}
            label="Background shadow"
          />
        </div>
      )}

      <div className="field">
        <label className="label" htmlFor={'image_alt' + id}>Image alt text</label>
        <div className="control">
          <input id={'image_alt' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.image_alt}
                 onChange={e => {onFieldChange(data.id, 'image_alt', e.target.value)}}
          />
        </div>
        <p className="help">Text that clearly and and concisely describes the image's content</p>
      </div>

      <div className="field">
        <label className="label" htmlFor={'title' + id}>Image title</label>
        <div className="control">
          <input id={'title' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.title}
                 onChange={e => {onFieldChange(data.id, 'title', e.target.value)}}
          />
          <p className="help">Text that appears as a tooltip when hovering over the image</p>
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'href' + id}>Link</label>
        <div className="control">
          <input id={'href' + id}
                 className="input"
                 type="text"
                 placeholder="https://example.com"
                 value={data.href}
                 onChange={e => {onFieldChange(data.id, 'href', e.target.value)}}
          />
        </div>
      </div>
    </>
  );
}
