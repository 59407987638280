import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import api from '../api';


export default function PageImage({ token, logout, data, setData, websiteReference }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [error, setError] = useState('');
  const endpoint = `/websites/${websiteReference}`;

  useEffect(() => {
    if(data.image) {
      api(`${endpoint}/${data.image}`, token, logout)
        .then(response => response.blob())
        .then(response => setSelectedImage(response))
        .catch(() => setError('Error loading image'));
    }
  }, [token, logout, data.image, endpoint]);

  const onImageChange = (event) => {
    const image = event.target.files[0];
    if(!image) return;

    setImageLoading(true);
    // The leading slash is needed as the image URL will be made absolute
    const filename = `/assets/img/${image.name}`;
    setSelectedImage(image);
    setError('');

    // Upload the image
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_field', 'file');
    api(`${endpoint}/${filename}`, '', logout, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    })
      .then(response => {
        if(response.error) {
          setError(response.message);
        } else {
          setData({...data, image: filename})
        }
        setImageLoading(false);
      })
      .catch(e => setError(e.toString()));

    event.target.value = '';
  }

  const deleteImage = (event) => {
    setData({...data, image: ''});
    setSelectedImage(null);
    event.preventDefault();
  };

  return (
    <>
      <div className={`file is-boxed ${selectedImage && data.image && 'has-name'}`}>
        <label className="file-label">
          <input className="file-input"
                 type="file"
                 onChange={onImageChange}
          />
          <span className="file-cta">
            <span className="file-icon">
              <FontAwesomeIcon icon={faUpload} />
            </span>
            <span className="file-label">Choose an image...</span>
          </span>
          {selectedImage && data.image && (
            <span className="file-name">
              {data.image}
              <button className="delete is-small mt-1 ml-2"
                      onClick={deleteImage}></button>
            </span>
          )}
        </label>
      </div>

      {!error && selectedImage && (
        <div className="mt-2">
          <figure className={`image-preview ${imageLoading && 'is-loading'}`}>
            <img alt="Preview of uploaded file" src={URL.createObjectURL(selectedImage)} />
          </figure>
        </div>
      )}

      {error && (
        <div className="block">
          <span className="tag is-medium is-danger">{error}</span>
        </div>
      )}
    </>
  );
}


PageImage.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
