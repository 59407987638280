import Select from 'react-select';
import MarkdownEditor from '../page-editor/markdown-editor';


export default function ButtonCardModule({ data, onFieldChange }) {
  const id = data.id;

  const options = [
    {value: 'download', label: 'Download map'},
    {value: 'facebook', label: 'Share on Facebook'},
    {value: 'book', label: 'Book tickets'}
  ];

  return (
    <>
      <div className="field">
        <label className="label" htmlFor={'title' + id}>Title</label>
        <div className="control">
          <input id={'title' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.title}
                 onChange={e => {onFieldChange(data.id, 'title', e.target.value)}} />
        </div>
      </div>

      <div className="field">
        <label className="label">Content</label>
        <MarkdownEditor content={data.content} setContent={c => onFieldChange(data.id, 'content', c)} />
      </div>

      <div className="field">
        <label className="label">Card type</label>
        <div className="control">
          <Select menuPortalTarget={document.body}
                  options={options}
                  defaultValue={options.filter(o => o.value === data.type)}
                  onChange={v => onFieldChange(data.id, 'type', v.value)} />
        </div>
      </div>
    </>
  );
}
