import ImageUpload from '../page-editor/image-upload';


export default function SmallCardModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label">Image</label>
        <ImageUpload token={token}
                     logout={logout}
                     srcField="image_src"
                     data={data}
                     onFieldChange={onFieldChange}
                     websiteReference={websiteReference} />
      </div>

      <div className="field">
        <label className="label" htmlFor={'image_alt' + id}>Image alt text</label>
        <div className="control">
          <input id={'image_alt' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.image_alt}
                 onChange={e => {onFieldChange(data.id, 'image_alt', e.target.value)}}
          />
          <p className="help">Text that clearly and and concisely describes the image's content</p>
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'text' + id}>Text</label>
        <div className="control">
          <input id={'text' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.text}
                 onChange={e => {onFieldChange(data.id, 'text', e.target.value)}} />
        </div>
      </div>
    </>
  );
}
