import { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import ContentModule from '../content-modules/content-module';


// Bulma mobile breakpoint
const MOBILE = 768;


export default function RowEditor({token, logout, rowIndex, row, onFieldChange, addColumn, removeColumn, removeRow, websiteReference }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener('resize', () => setWidth(window.innerWidth));
    }
  }, []);

  return (
    <Droppable droppableId={`${rowIndex}`} type="column" direction={width <= MOBILE ? 'vertical' : 'horizontal'}>
      {(provided, snapshot) => (
        <div className="columns content-module-columns" ref={provided.innerRef}>

          {row.columns.map((column, index) => (
            <Draggable key={column.id} draggableId={column.id} index={index}>
              {(provided, snapshot) => (
                <>
                  <div className="column" ref={provided.innerRef} {...provided.draggableProps}>
                    <ContentModule dragHandleProps={provided.dragHandleProps}
                                   token={token}
                                   logout={logout}
                                   data={column}
                                   onFieldChange={onFieldChange}
                                   removeColumn={removeColumn}
                                   websiteReference={websiteReference} />
                  </div>

                  {provided.placeholder}
                </>
              )}
            </Draggable>
          ))}
          {provided.placeholder}

          {!row.columns.length && (
            <div className="column has-text-centered">
              <button className="button is-link"
                      onClick={() => removeRow(row.id)}>
                Delete row
              </button>
            </div>
          )}

        {row.columns.length < 12 && (
          <div className="column is-1 has-text-centered">
            <button className="button"
                    onClick={() => addColumn(row.id)}
                    title="Add column">
              <span className="icon is-size-5 mt-1">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>
        )}

        </div>
      )}
    </Droppable>
  );
}


RowEditor.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  rowIndex: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  addColumn: PropTypes.func.isRequired,
  removeColumn: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
