import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import Switch from '../switch/switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripLines,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

import RowEditor from './row-editor';
import PageMetadata from '../page-metadata/page-metadata';


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


export default function PageEditor({ token, logout, pageData, setPageData, websiteReference }) {
  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    let newRows;
    if (result.type === "droppableRow") {
      newRows = reorder(pageData.rows, sourceIndex, destIndex);
    } else if (result.type === "column") {
      const rowColumnMap = pageData.rows.reduce((rows, row, index) => {
        rows[index] = row.columns;
        return rows;
      }, {});

      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);

      const sourceColumns = rowColumnMap[sourceParentId];
      const destColumns = rowColumnMap[destParentId];

      newRows = [...pageData.rows];

      // In this case columns are reordered inside same parent
      if (sourceParentId === destParentId) {
        const reorderedColumns = reorder(
          sourceColumns,
          sourceIndex,
          destIndex
        );
        newRows = newRows.map((row, index) => {
          if (index === sourceParentId) {
            row.columns = reorderedColumns;
          }
          return row;
        });
      } else {
        let newSourceColumns = [...sourceColumns];
        const [draggedRow] = newSourceColumns.splice(sourceIndex, 1);

        let newDestColumns = [...destColumns];
        newDestColumns.splice(destIndex, 0, draggedRow);
        newRows = newRows.map((row, index) => {
          if (index === sourceParentId) {
            row.columns = newSourceColumns;
          } else if (index === destParentId) {
            row.columns = newDestColumns;
          }
          return row;
        });
      }
    }
    setPageData({...pageData, rows: newRows});
  };

  const onFieldChange = (id, key, value) => {
    const rows = pageData.rows;

    rows.forEach(row => {
      row.columns.forEach(column => {
        if(column.id === id) {
          column[key] = value;
        }
      });
    });
    setPageData({...pageData, rows: rows});
  };

  const addColumn = (rowId) => {
    const rows = pageData.rows;

    rows.forEach(row => {
      if(row.id === rowId) {
        if(!row.columns.length) {
          row.columns.push({
            partial: '',
            content: '',
            id: `${rowId}_0`,
          });
        } else {
          for(let count = 0; true; count++) {
            if(!row.columns.some(c => c.id === `${rowId}_${count}`)) {
              row.columns.push({
                partial: '',
                content: '',
                id: `${rowId}_${count}`,
              });
              break;
            }
          }
        }
      }
    });

    setPageData({...pageData, rows: rows});
  };

  const removeColumn = (id) => {
    const rows = pageData.rows;

    rows.forEach((row, rowIndex) => {
      row.columns.forEach((column, index) => {
        if(column.id === id) {
          rows[rowIndex].columns.splice(index, 1);
        }
      });
    });
    setPageData({...pageData, rows: rows});
  };

  const addRow = () => {
    const rows = pageData.rows;

    for(let count = 0; true; count++) {
      if(!rows.some(r => r.id === `${count}`)) {
        rows.push({
          "id": `${count}`,
          "wide": false,
          "columns": [],
        });
        break;
      }
    }

    setPageData({...pageData, rows: rows});
  };

  const removeRow = (id) => {
    const rows = pageData.rows;
    rows.forEach((row, index) => {
      if(row.id === id) {
        rows.splice(index, 1);
      }
    });
    setPageData({...pageData, rows: rows});
  };

  return (
    <>
      <PageMetadata token={token}
                    logout={logout}
                    data={pageData}
                    setData={setPageData}
                    websiteReference={websiteReference} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" type="droppableRow">
          {(provided, snapshot) => (
            <div ref={provided.innerRef}>
              {pageData.rows.map((row, index) => (
                <Draggable key={`${index}`} draggableId={`${index}`} index={index}>
                  {(provided, snapshot) => (
                    <>
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div className="drag-handle" {...provided.dragHandleProps}>
                        <hr />
                        <nav className="level is-mobile mb-4">
                          <div className="level-left">
                            <h3 className="title is-4 has-text-primary">Row {`${index + 1}`}</h3>
                          </div>
                          <div className="level-right">
                            {!row.unmanaged && websiteReference !== 'universal' && (
                              <div className="has-text-primary mr-4">
                                <Switch
                                  value={row.wide}
                                  setValue={() => {
                                    pageData.rows[index].wide = !pageData.rows[index].wide;
                                    setPageData(pageData);
                                  }}
                                  id={`row_width_${index}`}
                                  label="Wide row"
                                />
                              </div>
                            )}

                            <span className="icon has-text-primary">
                              <FontAwesomeIcon icon={faGripLines} />
                            </span>
                          </div>
                        </nav>
                        </div>
                        {row.unmanaged && (
                          <>
                            <span className="tag is-warning is-light mb-4">Content not managed in the CMS</span>
                          </>
                        )}

                        <RowEditor token={token}
                                   logout={logout}
                                   row={row}
                                   rowIndex={index}
                                   onFieldChange={onFieldChange}
                                   addColumn={addColumn}
                                   removeColumn={removeColumn}
                                   removeRow={removeRow}
                                   websiteReference={websiteReference} />
                      </div>
                    {provided.placeholder}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}

              <div className="columns mt-6">
                <div className="column has-text-centered add-row">
                  <button className="button"
                          onClick={addRow}>
                    <span className="icon is-size-5">
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                    <span>Add row</span>
                  </button>
                </div>
              </div>

            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}


PageEditor.propTypes = {
  token: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  pageData: PropTypes.object.isRequired,
  setPageData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
