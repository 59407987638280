import { useState, useMemo } from 'react';
import Markdown from 'marked-react';
import ReactDOMServer from "react-dom/server";
import PropTypes from 'prop-types';
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";


export default function MarkdownEditor({ content, setContent }) {
  const [value, setValue] = useState(content);

  const saveValue = value => {
    setValue(value);
    setContent(value);
  };

  const options = useMemo(() => {
    return {
      previewRender: (plainText) => ReactDOMServer.renderToString(
        <div class="content">
          <Markdown value={plainText} breaks={true} gfm={true} />
        </div>
      ),
      renderingConfig: {
        markedOptions: {
          gfm: true,
          breaks: true,
        }
      },
      minHeight: "50px",
      spellChecker: false,
      toolbar: [
        "bold",
        "italic",
        "strikethrough",
        "heading",
        "quote",
        "unordered-list",
        "ordered-list",
        "link",
        "|",
        "preview",
      ]
    };
  }, []);

  return (
    <SimpleMDE value={value} onChange={saveValue} options={options} />
  );
};


MarkdownEditor.propTypes = {
  content: PropTypes.string.isRequired,
  setContent: PropTypes.func.isRequired
};
