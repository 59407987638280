import { HashRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import useToken       from './components/login/use-token';
import api            from './components/api';
import Navbar         from './components/navbar/navbar';
import Footer         from './components/footer/footer';
import Login          from './components/login/login';
import PasswordReset  from './components/password/reset';
import PasswordChange from './components/password/change';
import WebsiteList    from './components/websites/website-list';
import Page           from './components/pages/page';
import PageList       from './components/pages/page-list';
import GlobalContent  from './components/global-content/global-content';
import FooterContent  from './components/footer-content/footer-content';
import Navigation  from './components/navigation/navigation';


export default function App() {
  const { token, setToken } = useToken();

  function logout() {
    const oldToken = token;
    setToken('');

    api('/authenticate/logout', '', () => {}, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${oldToken}`,
        'Content-Length': '0',
      },
    });
  }

  return (
    <HashRouter>

      {/* Navigation bar */}
      <Switch>
        <Route exact
               path="/websites/:reference/"
               render={({match}) => <Navbar token={token} logout={logout} website={match.params.reference} active="pages" />}
        />
        <Route path="/websites/:reference/pages"
               render={({match}) => <Navbar token={token} logout={logout} website={match.params.reference} active="pages" />}
        />
        <Route path="/websites/:reference/global"
               render={({match}) => <Navbar token={token} logout={logout} website={match.params.reference} active="global" />}
        />
        <Route path="/websites/:reference/footer"
               render={({match}) => <Navbar token={token} logout={logout} website={match.params.reference} active="footer" />}
        />
        <Route path="/websites/:reference/navigation"
               render={({match}) => <Navbar token={token} logout={logout} website={match.params.reference} active="navigation" />}
        />
        <Route render={() => <Navbar token={token} logout={logout} />} />
      </Switch>

      <section className="section section-cms animate pop delay-1">
        <Switch>
          <Route exact
                 path="/websites/:reference/"
                 render={({match}) => token ? <PageList token={token} logout={logout} reference={match.params.reference} /> : <Login setToken={setToken} />}
          />
          <Route exact
                 path="/websites/:reference/pages"
                 render={({match}) => token ? <PageList token={token} logout={logout} reference={match.params.reference} /> : <Login setToken={setToken} />}
          />
          <Route exact
                 path="/websites/:reference/pages/:path*"
                 render={({match}) => {
                   if(!token) return <Login setToken={setToken} />

                   const website = match.params.reference;
                   const page = match.params.path;
                   if(page === undefined) return;

                   return <Page token={token} logout={logout} reference={website} path={page} />
                 }}
          />
          <Route exact
                 path="/websites/:reference/global"
                 render={({match}) => {
                   if(!token) return <Login setToken={setToken} />
                   const website = match.params.reference;
                   return <GlobalContent token={token} logout={logout} reference={website} />
                 }}
          />
          <Route exact
                 path="/websites/:reference/footer"
                 render={({match}) => {
                   if(!token) return <Login setToken={setToken} />
                   const website = match.params.reference;
                   return <FooterContent token={token} logout={logout} reference={website} />
                 }}
          />
          <Route exact
                 path="/websites/:reference/navigation"
                 render={({match}) => {
                   if(!token) return <Login setToken={setToken} />
                   const website = match.params.reference;
                   return <Navigation token={token} logout={logout} reference={website} />
                 }}
          />

          {/* Password change flow */}
          <Route path="/password/reset" component={PasswordReset} />
          <Route path="/password/change/:token" render={({match}) => <PasswordChange resetToken={match.params.token} logout={logout} />} />

          {/* Default to website list */}
          <Route render={() => token ? <WebsiteList token={token} logout={logout} /> : <Login setToken={setToken} />} />
        </Switch>
      </section>

      <Footer />
    </HashRouter>
  );
}
