import ImageUpload from '../page-editor/image-upload';


export default function HeroModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label" htmlFor={'title' + id}>Title</label>
        <div className="control">
          <input id={'title' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.title}
                 onChange={e => {onFieldChange(data.id, 'title', e.target.value)}} />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'subtitle' + id}>Subtitle</label>
        <div className="control">
          <input id={'subtitle' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.subtitle}
                 onChange={e => {onFieldChange(data.id, 'subtitle', e.target.value)}}
          />
        </div>
      </div>

      {/* Sub-subtitle is only visible on large hero modules */}
      {data.size === 'is-large' && (
        <div className="field">
          <label className="label" htmlFor={'subsubtitle' + id}>Tagline</label>
          <div className="control">
            <input id={'subsubtitle' + id}
                   className="input"
                   type="text"
                   placeholder=""
                   value={data.subsubtitle}
                   onChange={e => {onFieldChange(data.id, 'subsubtitle', e.target.value)}}
            />
          </div>
          <p className="help">Text displayed prominently below the main titles</p>
        </div>
      )}

      <div className="field">
        <label className="label">Image</label>
        <ImageUpload token={token}
                     logout={logout}
                     srcField="image_src"
                     data={data}
                     onFieldChange={onFieldChange}
                     websiteReference={websiteReference} />
      </div>

      <div className="field">
        <label className="label" htmlFor={'image_alt' + id}>Image alt text</label>
        <div className="control">
          <input id={'image_alt' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.image_alt}
                 onChange={e => {onFieldChange(data.id, 'image_alt', e.target.value)}}
          />
          <p className="help">Text that clearly and and concisely describes the image's content</p>
        </div>
      </div>
    </>
  );
}
