export default function CtaModule({ data, onFieldChange }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label" htmlFor={'text' + id}>Text</label>
        <div className="control">
          <input id={'text' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.text}
                 onChange={e => {onFieldChange(data.id, 'text', e.target.value)}} />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'href' + id}>Link</label>
        <div className="control">
          <input id={'href' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.href}
                 onChange={e => {onFieldChange(data.id, 'href', e.target.value)}} />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'icon' + id}>Icon</label>
        <div className="control">
          <input id={'icon' + id}
                 className="input"
                 type="icon"
                 placeholder="fa-solid fa-earth-africa"
                 value={data.icon}
                 spellCheck="false"
                 onChange={e => {onFieldChange(data.id, 'icon', e.target.value)}} />
        </div>
        <p className="help">Icon to use for the button, <a href="https://fontawesome.com/search?s=solid%2Cbrands">click here to search for Font Awesome icons</a>.</p>
      </div>
    </>
  );
}
