import PropTypes from 'prop-types';


export const BASE_URL = process.env.REACT_APP_API_URL || 'https://api-staging.cpdev.net';


class BadRequestError extends Error {}


/**
 * Make an API request and return the JSON response
 *
 * Returns JSON if possible, otherwise returns the response object.
 *
 * @callback unauthCallback
 * @param {string} endpoint - API endpoint without the base URL, for example: `/websites`
 * @param {string} token - Authentication token
 * @param {unauthCallback} unauthenticated - Callback to handle 401 responses from the API
 * @param {Object} config - Custom config options for the request, for example: `{ method: 'POST' }`
 */
export default function api(endpoint, token, unauthenticated, config) {
  config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    ...config
  }

  return fetch(`${BASE_URL}${endpoint}`, config)
    .then(async response => {
      let json;
      try {
        json = await response.clone().json();
      } catch(e) {}

      if(response.status === 401) unauthenticated();
      if(response.status === 400) throw new BadRequestError(json ? json.message : 'Bad request');
      return json || response;
    });
}


api.propTypes = {
  endpoint: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  unauthenticated: PropTypes.func.isRequired,
  config: PropTypes.object,
};
