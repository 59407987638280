export default function YoutubeModule({ token, logout, data, onFieldChange, websiteReference }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label" htmlFor={'url' + id}>Embed URL</label>
        <div className="control">
          <input id={'url' + id}
                 className="input"
                 type="url"
                 placeholder="https://www.youtube.com/embed/y04fDEcJjW4"
                 value={data.url}
                 onChange={e => {onFieldChange(data.id, 'url', e.target.value)}}
          />
        </div>
        <p className="help">Link to the video that begins with <code>https://www.youtube.com/embed/</code></p>
      </div>
    </>
  );
}
