import MarkdownEditor from '../page-editor/markdown-editor';


export default function ThreeStepButtonModule({ data, onFieldChange }) {
  const id = data.id;

  return (
    <>
      <div className="field">
        <label className="label">Step 1</label>
        <div className="control">
          <MarkdownEditor content={data.step_1} setContent={c => onFieldChange(data.id, 'step_1', c)} />
        </div>
      </div>

      <div className="field">
        <label className="label">Step 2</label>
        <div className="control">
          <MarkdownEditor content={data.step_2} setContent={c => onFieldChange(data.id, 'step_2', c)} />
        </div>
      </div>

      <div className="field">
        <label className="label">Step 3</label>
        <div className="control">
          <MarkdownEditor content={data.step_3} setContent={c => onFieldChange(data.id, 'step_3', c)} />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor={'button_text' + id}>Button text</label>
        <div className="control">
          <input id={'button_text' + id}
                 className="input"
                 type="text"
                 placeholder=""
                 value={data.button_text}
                 onChange={e => {onFieldChange(data.id, 'button_text', e.target.value)}} />
        </div>
      </div>
    </>
  );
}
