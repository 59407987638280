import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MarkdownEditor from '../page-editor/markdown-editor';


export default function FooterContentEditor({ data, setData, websiteReference }) {
  return (
    <>
      <hr />
      {/* GCRP uses free-form text content for the footer */}
      {websiteReference === 'gcrp' && (
        <div className="field">
          <label className="label">Text content</label>
          <div className="control">
            {/* Wait until the API request is finished before rendeing MarkdownEditor */}
            {typeof data.content !== 'undefined'  && (
              <MarkdownEditor content={data.content || ''} setContent={c => setData({...data, content: c})} />
            )}
          </div>
        </div>
      )}
      {websiteReference !== 'gcrp' && (
        <>
          <div className="field">
            <label className="label" htmlFor="site_name">Website name</label>
            <div className="control">
              <input id="site_name"
                     className="input"
                     type="text"
                     placeholder="Acme Corporation"
                     value={data.site_name || ''}
                     onChange={e => setData({...data, site_name: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="mobile">Mobile number</label>
            <div className="control">
              <input id="mobile"
                     className="input"
                     type="tel"
                     placeholder="01234 567890"
                     value={data.mobile || ''}
                     onChange={e => setData({...data, mobile: e.target.value})}
              />
            </div>
            <p className="help">Mobile phone number displayed to users</p>
          </div>

          <div className="field">
            <label className="label" htmlFor="mobile_international">Mobile with country code</label>
            <div className="control">
              <input id="mobile_international"
                     className="input"
                     type="tel"
                     pattern="[+]{1}[0-9]{11,14}"
                     placeholder="+441234567890"
                     value={data.mobile_international || ''}
                     onChange={e => setData({...data, mobile_international: e.target.value})}
              />
            </div>
            <p className="help">Full mobile number including the country code prefix</p>
          </div>

          <article class="message">
            <div class="message-body">
              The landline telephone number can be changed in the <Link to={`/websites/${websiteReference}/global`}>global content editor</Link>.
            </div>
          </article>

          <div className="field">
            <label className="label" htmlFor="email">Email address</label>
            <div className="control">
              <input id="email"
                     className="input"
                     type="email"
                     placeholder=""
                     value={data.email || ''}
                     onChange={e => setData({...data, email: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="address">Street address</label>
            <div className="control">
              <input id="address"
                     className="input"
                     type="text"
                     placeholder=""
                     value={data.address || ''}
                     onChange={e => setData({...data, address: e.target.value})}
              />
            </div>
          </div>

          <div className="field">
            <label className="label" htmlFor="copyright_notice">Copyright notice</label>
            <div className="control">
              <input id="copyright_notice"
                     className="input"
                     type="text"
                     placeholder=""
                     value={data.copyright_notice || ''}
                     onChange={e => setData({...data, copyright_notice: e.target.value})}
              />
            </div>
          </div>          
        </>
      )}

      <div className="field">
        <label className="label" htmlFor="facebook">Facebook link</label>
        <div className="control">
          <input id="facebook"
                 className="input"
                 type="url"
                 placeholder=""
                 value={data.facebook || ''}
                 onChange={e => setData({...data, facebook: e.target.value})}
          />
        </div>
      </div>

      {/* GCRP doesn't use Linkedin */}
      {websiteReference !== 'gcrp' && (
        <div className="field">
          <label className="label" htmlFor="linkedin">Linkedin link</label>
          <div className="control">
            <input id="linkedin"
                   className="input"
                   type="url"
                   placeholder=""
                   value={data.linkedin || ''}
                   onChange={e => setData({...data, linkedin: e.target.value})}
            />
          </div>
        </div>
      )}

      <div className="field">
        <label className="label" htmlFor="twitter">Twitter link</label>
        <div className="control">
          <input id="twitter"
                 className="input"
                 type="url"
                 placeholder=""
                 value={data.twitter || ''}
                 onChange={e => setData({...data, twitter: e.target.value})}
          />
        </div>
      </div>

      <div className="field">
        <label className="label" htmlFor="instagram">Instagram link</label>
        <div className="control">
          <input id="instagram"
                 className="input"
                 type="url"
                 placeholder=""
                 value={data.instagram || ''}
                 onChange={e => setData({...data, instagram: e.target.value})}
          />
        </div>
      </div>
    </>
  );
}


FooterContentEditor.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  websiteReference: PropTypes.string.isRequired,
};
